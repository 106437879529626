import React from 'react';
import getText from "./language";

import ButtonStyle from './css/button.module.css';
import ArrowStyle from './css/arrow.module.css';


class MainButton extends React.Component {
    onClick = () => {
        this.props.handler.createSub(this.props.tag);
    } 
    render() {
        const textData = getText([this.props.tag]);
        console.log(textData, textData.sub);
        return (
            <div className={`${ButtonStyle.mainButton}`} onClick={this.onClick}>
                <div>{textData.title}</div>
                <div>{textData.mainExplain}</div>
                <div>{
                    getText(["public", "pieces"]).replace("$COUNT", textData.sub.length)
                }</div>
                <div></div>
            </div>
        ); 
    }
};

class SubButton extends React.Component {
    onClick = () => {
        this.props.handler.viewDetail(this.props.tag);
    }
    render() {
        return (
            <div className={`${ButtonStyle.subButton}`} onClick={this.onClick} >
                <div>{getText(this.props.tag).title}</div>
                <div></div>
            </div>
        );
    }
};

class Arrow extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            isOn : props.isOn
        };
    }
    active = ()=>{
        this.setState({isOn : true});
    }
    inactive = ()=>{
        this.setState({isOn : false});
    }
    render(){
        const {type, event} = this.props;
        //${subType !== "" ? Style[subType]:""}
        if(type === "home"){
            return (<div className={`${ArrowStyle.home} ${ArrowStyle.icon}`} onClick={event}></div>);
        }else{
            return (<div className={`${ArrowStyle[type + (!this.state.isOn ? "-dis" : "")]} ${ArrowStyle.icon}`} onClick={event}></div>)
        }
        // ${!this.state.isOn ? Style["arrows-" + type + "-dis"] : ""}
    }
}

class SubPagePrevBtn extends React.Component {
    render(){
        return(<div className={`${ArrowStyle.icon}`} onClick={()=>{this.props.onClick()}}></div>)
    }
}


const exportedObject = {
    MainButton,
    SubButton,
    Arrow,
    SubPagePrevBtn
};
export default exportedObject;

