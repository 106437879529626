import React from 'react';
import Slider from "react-slick";
import getText, {languageType} from "./language";
import Button from './button';

import DetailStyle from './css/detail.module.css';
import "slick-carousel/slick/slick.css"; 
import "./css/slick-theme.css";


class DetailAdvice extends React.Component {
    constructor(props){
        super(props);

        const tag = this.props.tag;
        this.textData = getText(tag).text;        
        
        const url = "/images/adviceImg/";
        console.log(tag);
        
        this.images = Array.from({length:this.textData.length}, (el, idx)=>`${url}${languageType}/${tag[0]}/${tag[1]+(tag[2]+1)}/${idx+1}.png`);
        
        this.maxLength = this.images.length;

        this.state = {
            nowPage : 1,
            maxPage : this.maxLength
        }
    }
    next = ()=>{
        this.slider.slickNext();
    }
    prev = ()=>{
        this.slider.slickPrev();
    }
    home = ()=>{
        this.props.handler.createMain();
    }
    change = (oldIdx, newIdx) => {
        this.adviceFooter.setState({textNo:newIdx});
        this.setState({nowPage:newIdx+1});
        
        if(newIdx === this.maxLength-1){
            this.nextBtn.inactive();
        }else{
            this.nextBtn.active();
        }
        
        if(newIdx === 0){
            this.prevBtn.inactive();
        }else{
            this.prevBtn.active();
        }
    }
    render() {
        const settings = {
            // dots: true,
            arrows : false,
            infinite: false,
            lazyLoad: true,
            speed: 500,
            slidesToShow: 1,
            className: DetailStyle.slickSlide,
            beforeChange : this.change
        //   slidesToScroll: 1
        };
        const tag = this.props.tag;
        return (
            <div>
                <div className={`${DetailStyle.main}`} >
                    <div>{`${this.state.nowPage}/${this.state.maxPage}`}</div>
                    <Slider ref={c => (this.slider = c)} {...settings} >
                        {
                            this.images.map((el, idx) => {
                                return <div key={idx}><img className={`${DetailStyle.item}`} src={`${el}`}  alt=''/></div>;
                            })
                        }
                    </Slider>
                </div>
                <div className={`${DetailStyle.footer}`}>
                <AdviceFooter tag={tag} ref={c=>this.adviceFooter = c}/>
                <div></div>
                <Button.Arrow type="prev" isOn={false} ref={c=>this.prevBtn = c} event={this.prev}/>
                <Button.Arrow type="home" event={this.home}/>
                <Button.Arrow type="next" isOn={(this.maxLength === 1 ? false : true)} ref={c=>this.nextBtn = c} event={this.next}/>
                </div>
            </div>
        );
    }
}
  
export default DetailAdvice;  


class AdviceFooter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            textNo : 0
        }
        this.text = getText(this.props.tag);
        this.title = this.text.title;
        this.text = this.text.text;
    }
    render(){
        return (
        <div className={DetailStyle.footerText}>
            <div>{this.title} </div>
            <div>{this.text[this.state.textNo].subtitle} </div>
            <div>{this.text[this.state.textNo].subtext} </div>            
        </div>)
        ;
    }
}