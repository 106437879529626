import React from 'react';
import ReactDOM from 'react-dom';
import Main from "./mainpage";
import DetailAdvice from "./detailAdvice";
import getText, {setData} from "./language";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import qs from 'qs';

import textData from './textData.json';
import "./css/index.css";


const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true 
});

let vh = window.innerHeight/100;
document.documentElement.style.setProperty("--vh", `${vh}px`);
window.onresize = ()=>{
    let vh = window.innerHeight/100;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
}


class Handler {
    constructor(){
        this.setData(textData);
    }
    setData=(data)=>{
        let language = setData(data, query.lang);
        document.documentElement.lang = language;
        
        document.title = getText(["public","title"]);

        let path = window.location.pathname;
        path = path.split("/");
        path.shift();
        
        if(path.length !== 2){
            this.createMain();
        }else{
            const langData = data[language];
            const tag = [`main${path[0]}`, "sub", Number(path[1]-1)];
            console.log(tag,langData);
            if (tag[0] in langData && tag[2] in langData[tag[0]].sub) {
                this.viewDetail(tag);
            } else {
                this.createMain();
            }
        }

    }
    createMain(){
        this.render(<Main type="main" handler={this}/>);
    }
    createSub(tag){
        this.render(<Main type="sub" handler={this} tag={tag}/>)
    }
    viewDetail(tag){
        this.render(<DetailAdvice handler={this} tag={tag} />);
    }

    render(el){
        ReactDOM.render(
            el,
            document.getElementById('root')
        );
    }
}
new Handler();  