import React from 'react';
import Button from './button';
import getText, {textData} from "./language";
import Style from './css/index.module.css';
import MainStyle from './css/mainpage.module.css';
import SubStyle from './css/subpage.module.css';


class Main extends React.Component {
    createMainButton(){
        const mainTags = Object.keys(getText([]));
        
        const buttons = (
            <div className={MainStyle.buttonWrap}>
                {mainTags.map(el => el !== "public" ? <Button.MainButton key={el} tag={el} handler={this.props.handler} /> : null) }
            </div>
        );
        return buttons;
    }
    createSubButton(){
        let {tag} = this.props;
        const subTags = textData[tag].sub;
        console.log(subTags);
        const buttons = (
            <div className={SubStyle.buttonArea}>
                <div className={SubStyle.buttonWrap}>
                    {subTags.map((el, idx) => <Button.SubButton key={idx} tag={[tag, "sub", idx]} handler={this.props.handler}/> ) }
                </div>
            </div>
        );
        return buttons;
    }
    createFoot(){
        if(getText(["public", "isBannerOpen"])){
            return (
            <div className={`${Style.footer}`}>
                <div></div>
                <div>{getText(["public", "footerTitle"])}</div>
                <div>{getText(["public", "footerSub"])}</div>
                <a rel="noreferrer" href={getText(["public", "bannerLink"])} target="_blank"><div /></a>    
            </div>);
        }else{
            return "";
        }
    }
    createMain(){
        return (
            <div>
                <div className={`${Style.header} ${MainStyle.header}`}>
                    <div>{getText(["public", "mainTitle1"])}</div>
                    <div>{getText(["public", "mainTitle2"])}</div>
                    <div>{getText(["public", "mainTitle3"])}</div>
                </div>
                <div className={`${MainStyle.main}`}>
                    {this.createMainButton()}
                </div>
                {this.createFoot()}
            </div>
        );
    }
    createSub(){
        return (
            <div>
                <div className={`${Style.header} ${SubStyle.header}`}> 
                    <Button.SubPagePrevBtn onClick={()=>{this.props.handler.createMain()}}/>
                    <div>{getText([this.props.tag,"title"])}</div>
                    <div></div>
                </div>
                <div className={`${SubStyle.main}`}>
                    <CheckManual />
                    {this.createSubButton()}
                </div>
                {this.createFoot()}
            </div>
        );
    }
    render() {
        if(this.props.type === "main")
            return this.createMain();
        else
            return this.createSub();
    }
}
  
export default Main;  


class CheckManual extends React.Component {
    render() {
        return (
        <div className={`${SubStyle.checkManual}`}>
            <div >
                <div className={SubStyle.rect1}></div>
                <div className={SubStyle.rect2}></div>
                <div className={SubStyle.rect3}></div>
                <div className={SubStyle.rect4}></div>
                <div>{getText(["public", "checkManual"])}</div>
                <div>{getText(["public", "checkManualSub"])}</div>
            </div>
        </div>);
    }
}